import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import Banner from "../../components/ProjectDetailPage/Banner"
import Description from "../../components/ProjectDetailPage/Description"
import Slideshow from "../../components/ProjectDetailPage/Slideshow"

const ProjectDetail = ({ data }) => {
  const project = data?.contentfulProjects

  return (
    <>
      <GatsbySeo
        title={`Project ${project?.title}`}
        titleTemplate="%s | BESHAUS"
      />
      <Banner title={project?.title} image={project?.featureImage} />
      <Description info={project?.info} description={project?.description} />
      <Slideshow projectTitle={project?.title} slideshow={project?.slideshow} />
    </>
  )
}

export default ProjectDetail

export const query = graphql`
  query ($id: String!) {
    contentfulProjects(id: { eq: $id }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      info {
        childMarkdownRemark {
          html
        }
      }
      featureImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      slideshow {
        id
        gatsbyImageData(width: 300, height: 300)
        file {
          url
        }
      }
    }
  }
`
