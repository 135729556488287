import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Description = ({ info, description }) => {
  return (
    <section className="bg-[#0A0A0A]">
      <article className="container mx-auto max-w-7xl px-6 py-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div
            className="project-detail-description font-raleway col-span-full lg:col-span-2"
            dangerouslySetInnerHTML={{
              __html: description?.childMarkdownRemark?.html,
            }}
          ></div>
          <div className="relative col-span-full lg:col-span-1 p-6">
            <div className="absolute top-0 left-0 w-full h-full">
              <StaticImage
                src="../../assets/images/project-info-background.jpg"
                className="w-full h-full"
                quality="100"
                placeholder="blurred"
                alt="project info background"
              />
            </div>
            <div
              className="relative project-detail-description font-raleway"
              dangerouslySetInnerHTML={{
                __html: info?.childMarkdownRemark?.html,
              }}
            ></div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default Description
