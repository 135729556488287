import React from "react"
import LightGallery from "lightgallery/react"
import "lightgallery/css/lightgallery-bundle.css"
import lgThumbnail from "lightgallery/plugins/thumbnail"
import lgZoom from "lightgallery/plugins/zoom"
import { getSrc, getSrcSet } from "gatsby-plugin-image"

const Slideshow = ({ projectTitle, slideshow }) => {
  const isSSR = typeof window === "undefined"

  return (
    <section className="bg-[#0A0A0A]">
      <div className="container mx-auto max-w-8xl px-6 py-12">
        {isSSR ? null : (
          <LightGallery
            onInit={() => console.log("lg init")}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            elementClassNames="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5"
          >
            {slideshow.map(image => (
              <a key={image.id} href={image.file.url} className="aspect-square">
                <img
                  src={getSrc(image)}
                  srcSet={getSrcSet(image)}
                  className="w-full h-full object-cover"
                  alt={projectTitle || "Project Images"}
                />
              </a>
            ))}
          </LightGallery>
        )}
      </div>
    </section>
  )
}

export default Slideshow
